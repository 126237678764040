// src/ReportGenerator/index.js

import React, { useState, useEffect, useMemo } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import StepOneDateChannels from './StepOneDateChannels';
import StepTwoCheckCredits from './StepTwoCheckCredits';
import StepThreeAddresses from './StepThreeAddresses';
import ConfirmationModal from './ConfirmationModal';
import '../../styles/ReportGenerator.css';

// Dummy function to convert USD cost to "tokens" (optional display)
function convertUsdToTokens(costUsd) {
  // e.g. $0.04 per token => costUsd * 25
  if (!costUsd) return 0;
  return costUsd * 25;
}

const ReportGenerator = () => {
  // Step management
  const [step, setStep] = useState(1);

  // Basic info
  const [address, setAddress] = useState('');
  const [ethAddress, setEthAddress] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [currency, setCurrency] = useState('USD');

  // Costs
  const [costUsd, setCostUsd] = useState(null);
  const [tokenCost, setTokenCost] = useState(null); // purely for display if you want

  // Credits
  const [userCredits, setUserCredits] = useState(null);   // in USD
  const [userWenPrice, setUserWenPrice] = useState(null); // in USD per WEN

  // UI states
  const [error, setError] = useState('');
  const [loadingCost, setLoadingCost] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const [progress, setProgress] = useState('');
  const [availableReports, setAvailableReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [loadingCredits, setLoadingCredits] = useState(false);

  // For final confirmation popup
  const [showModal, setShowModal] = useState(false);

  // For multi-step logic
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const socket = useMemo(() => io(backendUrl), [backendUrl]);

  // ---------- Socket.IO Handlers ----------
  useEffect(() => {
    if (address) {
      socket.emit('join', { room: address });
    }
  }, [address, socket]);

  useEffect(() => {
    socket.on('report_ready', (data) => {
      if (data.error) {
        setError(data.error);
      } else if (data.download_url) {
        setDownloadLink(data.download_url);
        // auto-download
        const link = document.createElement('a');
        link.href = data.download_url;
        link.download = 'report.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      fetchAvailableReports();
    });
    return () => socket.off('report_ready');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    socket.on('report_progress', (data) => {
      if (data.message) {
        setProgress(data.message);
      }
    });
    return () => socket.off('report_progress');
  }, [socket]);

  // ---------- API Calls ----------

  // (A) Step 1 => calculate cost
  const handleCalculateCost = async () => {
    setError('');
    setCostUsd(null);
    setTokenCost(null);
    setDownloadLink(null);

    if (!startDate) {
      setError("Please select a start date.");
      return;
    }
    if (selectedChannels.length === 0) {
      setError("Please select at least one channel.");
      return;
    }
    setLoadingCost(true);

    try {
      // Payment validation is optional if no address
      await axios.post(`${backendUrl}/api/validate_payment`, { address });

      const costResp = await axios.post(`${backendUrl}/api/calculate_cost`, {
        address,
        channels: selectedChannels,
        eth_address: ethAddress || null,
        start_date: startDate,
        end_date: endDate,
        currency
      });
      const usdCost = costResp.data.cost;
      setCostUsd(usdCost);

      // If you still want to display "token cost" as an estimate
      const tokensNeeded = convertUsdToTokens(usdCost);
      setTokenCost(tokensNeeded);

      // Move to step 2
      setStep(2);
    } catch (err) {
      console.error(err);
      if (err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError("An error occurred while calculating the cost.");
      }
    } finally {
      setLoadingCost(false);
    }
  };

  // (B) Step 2 => fetch user credits
  const fetchUserCredits = async () => {
    if (!address) return;
    setLoadingCredits(true);
    setError('');
    try {
      const resp = await axios.post(`${backendUrl}/api/get_user_credits`, {
        address,
        eth_address: ethAddress || null
      });
      // The backend returns { credits, balance, wen_price }
      // credits/balance = user credits in USD
      // wen_price = how many USD per WEN token
      setUserCredits(resp.data.balance);
      setUserWenPrice(resp.data.wen_price);
    } catch (err) {
      console.error(err);
      setError("Error fetching user credits.");
    } finally {
      setLoadingCredits(false);
    }
  };

  const fetchAvailableReports = async () => {
    if (!address) return;
    setLoadingReports(true);
    setError('');
    try {
      const resp = await axios.post(`${backendUrl}/api/get_available_reports`, {
        address,
        eth_address: ethAddress || null
      });
      setAvailableReports(resp.data.reports);
    } catch (err) {
      console.error(err);
      setError("Error fetching available reports.");
    } finally {
      setLoadingReports(false);
    }
  };

  // (C) Step 2 => next
  const handleNextStepFromCredits = () => {
    // For the actual logic, compare costUsd to userCredits
    if (costUsd == null) {
      setError("Please calculate cost first (Step 1).");
      return;
    }
    if (userCredits != null && userCredits < costUsd) {
      const neededUsd = costUsd - userCredits;
      setError(`You need ${neededUsd.toFixed(2)} more USD in credits. Please burn WEN first.`);
      return;
    }
    setStep(3);
  };

  // Step 3 => open final confirmation
  const handleNextStepAddresses = () => {
    if (userCredits != null && costUsd != null && userCredits < costUsd) {
      const neededUsd = costUsd - userCredits;
      setError(`Not enough credits. You only have ${userCredits} USD, need ${neededUsd.toFixed(2)} USD.`);
      return;
    }
    setShowModal(true);
  };

  // Confirm generation => step 4
  const confirmGeneration = async () => {
    setShowModal(false);
    setError('');
    setProgress('');
    setDownloadLink(null);
    setLoadingReport(true);

    try {
      const resp = await axios.post(`${backendUrl}/api/generate_report`, {
        address,
        channels: selectedChannels,
        report_type: 'complete_data',
        eth_address: ethAddress || null,
        start_date: startDate,
        end_date: endDate,
        currency
      });
      console.log("Job ID:", resp.data.job_id);
      setStep(4); // generation in progress
    } catch (err) {
      console.error(err);
      if (err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError("An error occurred while generating the report.");
      }
    } finally {
      setLoadingReport(false);
    }
  };

  // (D) Whenever address changes => fetch credits & reports
  useEffect(() => {
    if (address) {
      fetchUserCredits();
      fetchAvailableReports();
    } else {
      setUserCredits(null);
      setAvailableReports([]);
    }
    // eslint-disable-next-line
  }, [address]);

  return (
    <div className="report-generator">
      {/* Title with BETA in red & bold */}
      <h2>
        Tax Report Generation <span className="beta-text">BETA</span>
      </h2>

      {/* Red disclaimer */}
      <p className="red-text">
        This tool is in Beta testing.<br /><br />
        Read the <a href="faq.html">FAQ</a> before starting the process.<br /><br />
        While it has been heavily tested, you might encounter issues. If this happens to you or if you have questions before trying it, reach out to @lukabylie in Discord.
      </p>

      {/* Step 1: Date + Channels */}
      {step === 1 && (
        <StepOneDateChannels
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedChannels={selectedChannels}
          setSelectedChannels={setSelectedChannels}
          currency={currency}
          setCurrency={setCurrency}
          handleCalculateCost={handleCalculateCost}
          loadingCost={loadingCost}
          error={error}
        />
      )}

      {/* Step 2: Credits & Address */}
      {step === 2 && (
        <StepTwoCheckCredits
          address={address}
          setAddress={setAddress}
          ethAddress={ethAddress}
          setEthAddress={setEthAddress}
          userCredits={userCredits}       // USD
          userWenPrice={userWenPrice}     // USD/WEN
          costUsd={costUsd}               // cost in USD
          tokenCost={tokenCost}           // optional display
          loadingCredits={loadingCredits}
          fetchUserCredits={fetchUserCredits}
          onNext={handleNextStepFromCredits}
          error={error}
        />
      )}

      {/* Step 3: Additional addresses or final check */}
      {step === 3 && (
        <StepThreeAddresses
          address={address}
          setAddress={setAddress}
          ethAddress={ethAddress}
          setEthAddress={setEthAddress}
          onNext={handleNextStepAddresses}
          error={error}
        />
      )}

      {/* Step 4: Generation Ongoing */}
      {step >= 4 && (
        <div>
          <h3>Report Generation</h3>
          {progress && <p><strong>Progress: {progress}</strong></p>}
          {downloadLink && (
            <div className="download-link">
              <a href={downloadLink} download="report.zip">Download Report</a>
            </div>
          )}
        </div>
      )}

      {/* Always show available reports from Step 2 onward */}
      {step >= 2 && (
        <div className="available-reports">
          <h3>Available Reports</h3>
          {loadingReports ? (
            <p>Loading available reports...</p>
          ) : availableReports.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Channels</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {availableReports.map((r, idx) => {
                  let channelsStr = '';
                  try {
                    const arr = JSON.parse(r.channels);
                    channelsStr = arr.join(", ");
                  } catch {}
                  const startStr = r.start_date
                    ? new Date(r.start_date).toLocaleString()
                    : 'N/A';
                  const endStr = r.end_date
                    ? new Date(r.end_date).toLocaleString()
                    : 'N/A';

                  return (
                    <tr key={idx}>
                      <td>{channelsStr}</td>
                      <td>{startStr}</td>
                      <td>{endStr}</td>
                      <td>{r.status}</td>
                      <td>
                        {r.download_url ? (
                          <a href={r.download_url} download="report.zip">
                            Download
                          </a>
                        ) : (
                          "Not available"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No reports available.</p>
          )}
        </div>
      )}

      {/* Confirmation modal for final generation */}
      {showModal && (
        <ConfirmationModal
          onClose={() => setShowModal(false)}
          onConfirm={confirmGeneration}
          loadingReport={loadingReport}
          address={address}
          ethAddress={ethAddress}
          startDate={startDate}
          endDate={endDate}
          costUsd={costUsd}
          tokenCost={tokenCost}
          channels={selectedChannels}
        />
      )}

      {/* Global error display */}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ReportGenerator;
