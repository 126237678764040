// src/ReportGenerator/ConfirmationModal.js

import React from 'react';

const ConfirmationModal = ({
  onClose,
  onConfirm,
  loadingReport,
  address,
  ethAddress,
  startDate,
  endDate,
  costUsd,
  tokenCost,
  channels
}) => {

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h3>Confirm Report Generation</h3>
        <p><strong>Channels:</strong> {channels.join(", ")}</p>
        <p><strong>Start Date:</strong> {startDate}</p>
        <p><strong>End Date:</strong> {endDate}</p>
        <p><strong>Cost (USD):</strong> {costUsd}</p>
        <p><strong>Cost (Tokens):</strong> {tokenCost}</p>
        <p><strong>Main Address:</strong> {address}</p>
        <p><strong>Ethereum Address:</strong> {ethAddress}</p>

        <div className="modal-actions">
          <button onClick={onConfirm} disabled={loadingReport}>
            {loadingReport ? "Generating..." : "Confirm"}
          </button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
