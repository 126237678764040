// src/ReportGenerator/StepTwoCheckCredits.js
import React from 'react';

const StepTwoCheckCredits = ({
  address,
  setAddress,
  ethAddress,
  setEthAddress,
  userCredits,   // USD
  userWenPrice,  // USD/WEN
  costUsd,       // cost in USD
  tokenCost,     // optional "internal tokens"
  loadingCredits,
  fetchUserCredits,
  onNext,
  error
}) => {
  // Handler to refresh credits
  const handleRefreshCredits = () => {
    if (!address) return;
    fetchUserCredits();
  };

  let creditsDisplay;
  if (loadingCredits) {
    creditsDisplay = <p>Loading credits...</p>;
  } else if (userCredits !== null) {
    // userCredits is in USD
    if (costUsd !== null) {
      if (userCredits < costUsd) {
        const neededUsd = costUsd - userCredits;
        const wenPrice = userWenPrice || 1; // fallback if null
        const wenNeeded = (neededUsd / wenPrice).toFixed(2);

        creditsDisplay = (
          <p style={{ color: 'red' }}>
            You have <strong>{userCredits.toFixed(2)} credits</strong>, but the cost is
            <strong> {costUsd.toFixed(2)} credits</strong>.
            <br />
            You need an additional <strong>{neededUsd.toFixed(2)} credits</strong>.
            <br />
            To get that, burn approximately <strong>{wenNeeded} WEN</strong> and refresh.
          </p>
        );
      } else {
        creditsDisplay = (
          <p style={{ color: 'green' }}>
            You have <strong>{userCredits.toFixed(2)} credits</strong>, and the cost is
            <strong> {costUsd.toFixed(2)} credits</strong>.
            <br />
            You have enough credits!
          </p>
        );
      }
    } else {
      // cost not calculated yet
      creditsDisplay = <p>You have {userCredits.toFixed(2)} credits.</p>;
    }
  } else {
    creditsDisplay = <p>No credits available.</p>;
  }

  return (
    <div>
      <h3>Step 2: Addresses & Credits</h3>

      <div className="form-group">
        <label>Main Address:</label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="client|604161f025e6931a676ccf37"
        />
      </div>

      <div className="form-group">
        <label>Ethereum Address (Optional & Case sensitive):</label>
        <input
          type="text"
          value={ethAddress}
          onChange={(e) => setEthAddress(e.target.value)}
          placeholder="0xAC8B60786cdDb77cAce8A1946D8aeF20b72cF561"
        />
      </div>

      {costUsd !== null && tokenCost !== null && (
        <p>Cost: <strong>${costUsd}</strong> (~{tokenCost} tokens)</p>
      )}

      <div className="user-credits">
        <h4>Your Credits</h4>
        {creditsDisplay}
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <button onClick={handleRefreshCredits} disabled={loadingCredits}>
        {loadingCredits ? "Refreshing..." : "Check / Refresh Credits"}
      </button>

      <button onClick={onNext} style={{ marginLeft: '1rem' }}>
        Next
      </button>
    </div>
  );
};

export default StepTwoCheckCredits;
