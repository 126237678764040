// src/components/ReportGenerator/StepOneDateChannels.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Example categories
const MAIN_CHANNELS = ["asset-channel"];
const SECONDARY_CHANNELS = ["galafilm", "node", "music"];

// We'll filter the rest from the full set
const ALL_CHANNELS = [
  "asset-channel", "legendsreborn", "mirandus", "rep", "superior",
  "thewalkingdeadempires", "galafilm", "vox", "node", "music",
  "battlestargalacticaeternity", "championsarena", "echoesofempire",
  "eternalparadox", "lastexpedition", "legacy"
];
const OTHER_CHANNELS = ALL_CHANNELS.filter(ch =>
  !MAIN_CHANNELS.includes(ch) && !SECONDARY_CHANNELS.includes(ch)
);

const StepOneDateChannels = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedChannels,
  setSelectedChannels,
  currency,
  setCurrency,
  handleCalculateCost,
  loadingCost,
  error
}) => {
  const [lastUpdate, setLastUpdate] = useState(null);
  const [updateWarning, setUpdateWarning] = useState('');

  // Fetch last update date
  useEffect(() => {
    const fetchLastUpdate = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get_last_update`);
        setLastUpdate(new Date(response.data.last_update)); // Convert to Date object
      } catch (err) {
        console.error("Error fetching last DB update:", err);
        setLastUpdate(null);
      }
    };
    fetchLastUpdate();
  }, []);

  // When user picks a date, store "YYYY-MM-DD 00:00:00"
  const handleDateChange = (e) => {
    const val = e.target.value; // "2025-03-01"
    if (!val) {
      setStartDate('');
      setEndDate('');
      setUpdateWarning('');
      return;
    }

    const finalStart = `${val} 00:00:00`;
    setStartDate(finalStart);

    // set end date +1 year
    const dateObj = new Date(val);
    dateObj.setFullYear(dateObj.getFullYear() + 1);
    const iso = dateObj.toISOString().split('T')[0]; // "2026-03-01"
    const finalEnd = `${iso} 00:00:00`;
    setEndDate(finalEnd);

    // Compare with last update date if available
    if (lastUpdate) {
      const endPeriod = new Date(iso);
      if (endPeriod < lastUpdate) {
        setUpdateWarning('');
      } else {
        setUpdateWarning("⚠️ Selected period extends past the last update.");
      }
    }
  };

  const handleChannelChange = (channel) => {
    if (selectedChannels.includes(channel)) {
      setSelectedChannels(selectedChannels.filter(ch => ch !== channel));
    } else {
      setSelectedChannels([...selectedChannels, channel]);
    }
  };

  return (
    <div>
      <h3>Step 1: Select Date & Channels</h3>

      <p>
        Please select the first day of your period. We'll automatically extend it to 1 year.
      </p>

      {/* Date input => type="date" */}
      <div className="form-group">
        <label>Start Date (report will extend to one year later):</label>
        <input type="date" onChange={handleDateChange} />
        {startDate && (
          <p style={{ fontWeight: 'bold', color: updateWarning ? 'orange' : 'green' }}>
            Selected period: {startDate} to {endDate}
          </p>
        )}
        {lastUpdate && (
          <p>📅 Last DB Update: {lastUpdate.toISOString().split('T')[0]}</p>
        )}
        {updateWarning && <p style={{ color: 'orange' }}>{updateWarning}</p>}
      </div>

      {/* Currency */}
      <div className="form-group">
        <label>Currency (Optional):</label>
        <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="CHF">CHF</option>
        </select>
      </div>

      {/* Channels in categories */}
      <div className="form-group">
        <h4>Main Channels (50 credits)</h4>
        <p>Most assets, most token/NFT related transactions (FN distribution, token transfers...)</p>
        {MAIN_CHANNELS.map(ch => (
          <div key={ch}>
            <input
              type="checkbox"
              checked={selectedChannels.includes(ch)}
              onChange={() => handleChannelChange(ch)}
            />
            <label>{ch}</label>
          </div>
        ))}

        <h4>Secondary Channels (10 credits each)</h4>
        <p>Film, Node (excluding FN distribution) & Music activity</p>
        {SECONDARY_CHANNELS.map(ch => (
          <div key={ch}>
            <input
              type="checkbox"
              checked={selectedChannels.includes(ch)}
              onChange={() => handleChannelChange(ch)}
            />
            <label>{ch}</label>
          </div>
        ))}

        <h4>Other Channels (5 credits each)</h4>
        <p>All others not in main or secondary (specific assets)</p>
        {OTHER_CHANNELS.map(ch => (
          <div key={ch}>
            <input
              type="checkbox"
              checked={selectedChannels.includes(ch)}
              onChange={() => handleChannelChange(ch)}
            />
            <label>{ch}</label>
          </div>
        ))}
      </div>

      <button onClick={handleCalculateCost} disabled={loadingCost}>
        {loadingCost ? "Calculating..." : "Calculate Cost"}
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default StepOneDateChannels;
