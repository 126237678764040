// src/ReportGenerator/StepThreeAddresses.js

import React from 'react';

const StepThreeAddresses = ({
  address,
  setAddress,
  ethAddress,
  setEthAddress,
  onNext,
  error
}) => {

  const handleMainChange = (e) => {
    setAddress(e.target.value);
  };
  const handleEthChange = (e) => {
    setEthAddress(e.target.value);
  };

  return (
    <div>
      <h3>Step 3: Enter Addresses</h3>

      <div className="form-group">
        <label>Main Address:</label>
        <input
          type="text"
          value={address}
          onChange={handleMainChange}
          placeholder="client|604161f025e6931a676ccf37"
        />
      </div>

      <div className="form-group">
        <label>Ethereum Address (Optional):</label>
        <input
          type="text"
          value={ethAddress}
          onChange={handleEthChange}
          placeholder="0x896B6B09f2604f8DA84AcE6ae39C722F68e4E215"
        />
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <p style={{ fontSize: '12px', color: 'gray', marginTop: '10px' }}>
        By generating the report, I acknowledge that this tool is designed to help filling tax report but that the responsibility of the declarations are the user's responsibility. I understand that I will be deducted credits and that they are not refundable.
      </p>

      <button onClick={onNext}>Next (Confirm Generation)</button>
    </div>
  );
};

export default StepThreeAddresses;
